<template>
  <div v-if="shouldShow">
    <q-expansion-item v-if="option.children" :label="option.label" :header-inset-level="inset" :group="`treeGroup-inset:${inset + .5}`" :ref="`ei-${option.uid}`">
      <template v-slot:header v-if="enableBranchNodes">
        <q-item v-close-popup class="full-width q-pa-none" dense>
          <q-item-section @click="choose(option)">{{ option.label }}</q-item-section>
        </q-item>
      </template>
      <TreeMenuItem v-for="child in option.children" :key="child.id" :option="child" :inset="inset + .5" @selection="choose" :enableBranchNodes="enableBranchNodes" :idValue="idValue" :labelValue="labelValue" :filterString="filterString" :excludedUid="excludedUid" />
    </q-expansion-item>
    <q-item clickable v-close-popup v-else :inset-level="inset" @click="choose(option)">
      <q-item-section>{{ option.label }}</q-item-section>
    </q-item>
  </div>
</template>
<script>
import TreeMenuItem from 'components/TreeMenuItem'
export default {
  name: 'TreeMenuItem',
  props: {
    option: {
      type: Object
    },
    inset: {
      type: Number,
      default: 0
    },
    enableBranchNodes: {
      type: Boolean,
      default: false
    },
    idValue: {
      type: String,
      default: null
    },
    labelValue: {
      type: String,
      default: null
    },
    filterString: {
      type: String,
      default: null
    },
    excludedUid: {
      type: String
    }
  },
  components: { TreeMenuItem },
  data () {
    return {
      showExpansion: false,
      flattenedChildren: []
    }
  },
  computed: {
    shouldShow () {
      let optionId = this.option.uid || this.option.id
      if (this.excludedUid === optionId) return false

      if (!this.filterString ||
        this.flattenedChildren.some(child => child.label.toLowerCase().includes(this.filterString.toLowerCase())) ||
        this.option.label.toLowerCase().includes(this.filterString.toLowerCase())) return true

      return false
    }
  },
  methods: {
    choose (option) {
      this.$emit('selection', option)
    },
    flattenChildren (level = this.option) {
      if (level.children) {
        level.children.forEach(child => {
          this.flattenedChildren.push(child)
          if (child.children) {
            this.flattenChildren(child)
          }
        })
      }
    }
  },
  watch: {
    filterString (newVal) {
      if (newVal && this.flattenedChildren.some(child => child.label.toLowerCase().includes(newVal.toLowerCase())) && this.$refs[`ei-${this.option.uid}`]) {
        this.$refs[`ei-${this.option.uid}`].show()
      } else if (this.$refs[`ei-${this.option.uid}`]) {
        this.$refs[`ei-${this.option.uid}`].hide()
      }
    }
  },
  created () {
    this.option.id = this.idValue ? this.option[this.idValue] : this.option.id
    this.option.label = this.labelValue ? this.option[this.labelValue] : this.option.label
    this.flattenChildren()
  }
}
</script>
