<template>
  <div>
    <q-select popup-content-class="bg-lmsBackground primaryText-text" label="Select Vendor" v-model="vendor" map-options @input="$emit('update:selection', vendor); $emit('input', vendor); filter = '';" use-input @new-value="checkForSimilarVendors" :options="filteredOptions" option-value="uid" option-label="name" @filter="filterFn" clearable @clear="$emit('update:selection', null); $emit('input', null);">
      <template v-slot:append v-if="filter.length > 0">
        <q-btn color="primary" @click="checkForSimilarVendors(filter)">Add Vendor</q-btn>
      </template>
      <template v-slot:no-option>
          <div class="row full-width justify-center q-pa-lg">
            <q-btn color="primary" @click="checkForSimilarVendors(filter)">Add Vendor</q-btn>
          </div>
      </template>
    </q-select>

    <q-dialog v-model="hasSimilarVendors" @hide="similarVendors = []">
      <q-card class="bg-lmsBackground primaryText-text">
        <q-card-section class="row full-width text-center justify-center">
          <div class="text-h5">Are you sure you aren't looking for one of these?</div>
          <div class="text-body2 q-mt-lg">If so, please select it</div>
        </q-card-section>
        <q-card-section>
          <q-list class="q-my-lg">
            <q-item v-for="similarVendor in similarVendors" :key="similarVendor.uid" clickable @click="chooseExistingVendor(similarVendor)">
              <q-item-section>
                {{ similarVendor.name }}
              </q-item-section>
              <q-item-section side>
                {{ similarVendor.match }}% Match
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-section>
          <q-btn color="primary" class="full-width" @click="addVendor(filter)" :disable="has100Match()">Yes I'm Sure, Add New Vendor</q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      vendor: null,
      vendors: [],
      filter: '',
      similarVendors: [],
      hasSimilarVendors: false,
      tempNewVendorName: null
    }
  },
  computed: {
    filteredOptions () {
      return this.vendors.filter(vendor => vendor.name.toLowerCase().includes(this.filter.toLowerCase()))
    }
  },
  methods: {
    checkForSimilarVendors (val) {
      this.tempNewVendorName = val
      this.vendors.forEach(vendor => {
        let percentSame = this.similarity(vendor.name, val)
        if (percentSame > 0.5) {
          this.similarVendors.push({ ...vendor, match: percentSame.toFixed(2) * 100 })
        }
      })

      if (this.similarVendors.length === 0) {
        this.addVendor(val)
      } else {
        this.hasSimilarVendors = true
      }
    },
    has100Match () {
      return this.similarVendors.some(vendor => vendor.match === 100)
    },
    chooseExistingVendor (vendor) {
      this.filter = ''
      this.vendor = vendor
      this.hasSimilarVendors = false
      this.$emit('input', vendor)
      this.$emit('update:selection', vendor)
    },
    async addVendor () {
      this.$loading(true, `Adding ${this.tempNewVendorName} to Global Vendor List`)
      try {
        let res = await this.$axios.post(this.$consts.STORE_GLOBAL_VENDOR_LIST_URL, { name: this.tempNewVendorName })
        this.vendors.push(res.data)
        this.vendor = res.data
        this.$emit('input', res.data)
        this.$emit('update:selection', res.data)
        this.filter = ''
        this.hasSimilarVendors = false
        this.$successMsg('Vendor Added')
        this.$loading(false)
      } catch (e) {
        this.$failureMsg()
        this.$loading(false)
      }
    },
    filterFn (val, update) {
      this.filter = val
      if (val.length > 0) {
        this.vendor = null
      } else {
        this.vendor = this.value
      }

      update(() => { })
    },
    similarity (s1, s2) {
      let longer = s1,
        shorter = s2
      if (s1.length < s2.length) {
        longer = s2
        shorter = s1
      }
      var longerLength = longer.length
      if (longerLength === 0) {
        return 1.0
      }
      return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength)
    },
    editDistance (s1, s2) {
      s1 = s1.toLowerCase()
      s2 = s2.toLowerCase()

      let costs = []
      for (let i = 0; i <= s1.length; i++) {
        let lastValue = i
        for (let j = 0; j <= s2.length; j++) {
          if (i === 0) {
            costs[j] = j
          } else {
            if (j > 0) {
              let newValue = costs[j - 1]
              if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
              }
              costs[j - 1] = lastValue
              lastValue = newValue
            }
          }
        }
        if (i > 0) {
          costs[s2.length] = lastValue
        }
      }
      return costs[s2.length]
    }
  },
  async beforeCreate () {
    this.$loading(true, 'Fetching Global Vendor List')
    let res = await this.$axios.get(this.$consts.GET_GLOBAL_VENDOR_LIST_URL)
    this.vendors = res.data
    this.$loading(false)
  },
  created () {
    this.vendor = this.value
  }
}
</script>
