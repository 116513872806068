<template>
    <q-card class="q-pa-lg bg-lmsBackground primaryText-text">
      <div class="row justify-between">
        <div class="text-h5">Browse Categories</div>
        <q-btn dense flat icon="close" style="z-index: 2" @click="$emit('closeModal')">
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </div>
      <div class="row text-italic">
        Click on a category image to drill down and see its Sub Categories
      </div>
      <div v-if="removeIfHasModules" class="row text-italic">
        Categories that have modules have been removed
      </div>
      <q-card-section>
        <div v-if="breadcrumbs.length > 1" class="row">
          <div v-for="(category, i) in breadcrumbs" :key="i" class="row items-center">
            <q-btn flat dense class="q-mx-xs" @click="openCategory(category)" :color="i !== breadcrumbs.length - 1 ? 'primary' : ''" :disable="i === breadcrumbs.length - 1">{{ category.name }}</q-btn>
            <span v-if="i !== breadcrumbs.length - 1" class="text-h6 primary-text">/</span>
          </div>
        </div>
        <div v-else-if="allowRearrangeOption" class="row">
          <q-btn color="primary" outline label="Rearrange Main Categories" @click="$emit('selectedCategory', {})" />
        </div>
      </q-card-section>
      <q-card-section class="row q-gutter-lg">
        <q-card class="bg-lmsBackground" style="min-width: 250px" v-for="category in displayCategories.filter(cat => cat.uid !== excludeCategoryUid)" :key="category.uid">
          <q-img :src="category.thumbnail_path" :class="category.children ? 'cursor-pointer' : 'cursor-not-allowed'" @click="category.children ? openCategory(category) : ''">
            <div v-if="category.settings.overlayTitle" class="absolute-full category-skim text-center text-weight-medium flex flex-center">
              <div class="font-oswald" :style="`font-size: ${getFontSize(category.name)}`">{{ category.name }}</div>
            </div>
          </q-img>

          <q-card-actions class="row jutify-center justify-around">
            <q-btn flat @click="$emit('selectedCategory', category)" :disable="!allowSelectAll && category.children ? true : false">Select</q-btn>
          </q-card-actions>
        </q-card>
      </q-card-section>
    </q-card>
</template>

<script>

export default {
  name: 'CategorySelect',
  props: {
    allowSelectAll: {
      type: Boolean,
      default: false
    },
    allowRearrangeOption: {
      type: Boolean,
      default: false
    },
    removeIfHasModules: {
      type: Boolean,
      default: false
    },
    excludeCategoryUid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      topLevelCategories: [],
      currentLevelCategories: [],
      breadcrumbs: [{ name: 'Main' }],
      selectedCategory: null
    }
  },
  computed: {
    displayCategories () {
      return this.currentLevelCategories.filter(category => {
        return this.removeIfHasModules ? !category.hasModules : true
      })
    }
  },
  methods: {
    openCategory (category) {
      let exists = this.breadcrumbs.findIndex(breadcrumb => breadcrumb.name === category.name) // this needs to be a value and not name
      if (exists > -1) {
        this.breadcrumbs = this.breadcrumbs.slice(0, exists + 1)
      } else {
        this.breadcrumbs.push(category)
      }
      this.currentLevelCategories = category.name === 'Main' ? this.topLevelCategories : category.children
    },
    getFontSize (name) {
      if (name.length <= 6) {
        // console.log(`${name} - ${name.length} - 52px`)
        return '56px'
      } else if (name.length <= 10) {
        return '48px'
      } else if (name.length <= 14) {
        return '40px'
      } else if (name.length <= 18) {
        return '32px'
      } else if (name.length <= 22) {
        return '28px'
      } else if (name.length <= 26) {
        return '24px'
      } else if (name.length <= 30) {
        return '20px'
      } else {
        return '16px'
      }
    }
  },
  async created () {
    this.$loading(true, 'Fetching Categories')
    this.$store.dispatch('categories/fetch', 'edit').then((res) => {
      this.topLevelCategories = res
      this.currentLevelCategories = res
      this.$loading(false)
    })
  }
}
</script>

<style lang="stylus">
.font-oswald
  font-family 'Oswald', sans-serif
.category-skim
  background rgba(0,0,0,0.57) !important
</style>
