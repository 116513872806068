<template>
  <q-card style="width: 560px;" class="bg-lmsBackground primaryText-text" v-if="report && report.settings && report.settings.options">
    <q-form @submit="type === 'email' ? emailReport() : updateSubscribers()" @reset="$emit('closeForm')" autocomplete="off">
      <q-card-section class="row items-center justify-between">
        <div class="text-h5">{{ type === 'email' ? 'Additional Recipients' : 'Subscribers' }}</div>
        <q-btn dense flat icon="close" style="z-index: 2" @click="$emit('closeForm')">
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </q-card-section>
      <q-card-section>
        <q-input v-model="filter" label="Filter Users" clearable>
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section>
        <q-list separator>
          <q-scroll-area style="height: 500px">
            <q-item v-for="(user, i) in users" :key="user.value">
              <q-item-section>{{ user.label }}</q-item-section>
              <q-item-section avatar>
                <q-btn flat round color="primary" :icon="getIcon(user)" @click="toggleUser(user, i)" />
              </q-item-section>
            </q-item>
          </q-scroll-area>
        </q-list>
      </q-card-section>
      <q-card-section class="row full-width q-mt-lg">
        <!-- <q-btn flat type="reset">Cancel</q-btn> -->
        <q-btn type="submit" :loading="loading" color="primary" class="full-width">{{ type === 'email' ? 'Send Report' : 'Update Subscribers' }}</q-btn>
      </q-card-section>
      <q-inner-loading :showing="loading">
        <q-spinner size="50px"/>
      </q-inner-loading>
    </q-form>
  </q-card>
</template>

<script>

export default {
  props: {
    type: {
      default: () => 'scheduledReport'
    },
    propReport: {
      default: () => {
        return {
          label: '',
          recurring_date: null,
          recurring_duration: null,
          settings: {
            chartType: 'spline',
            duration: '7',
            options: {
              filterBy: 'Company',
              groupBy: 'Locations'
            },
            user_uids: []
          }
        }
      }
    }
  },
  data () {
    return {
      report: null,
      usersList: [],
      loading: false,
      filter: ''
    }
  },
  computed: {
    users () {
      if (this.filter === '' || this.filter === null) return this.usersList
      return this.usersList.filter(user => user.label.toLowerCase().includes(this.filter.toLowerCase()))
    }
  },
  methods: {
    formData () {
      let fd = new FormData()
      fd.append('settings', JSON.stringify(this.report.settings))
      return fd
    },
    async updateSubscribers () {
      this.loading = true
      try {
        let res = await this.$axios.post(`${this.$consts.SCHEDULED_REPORTS_URL}/update/${this.report.uid}`, this.formData())
        this.loading = false
        this.$emit('closeForm', { type: 'update', data: res })
        this.$successMsg()
      } catch (e) {
        this.loading = false
        this.$failureMsg()
      }
    },
    getIcon (user) {
      if (this.report.user_uid === user.value) {
        // owner of report
        return 'account_circle'
      } else if (this.userIsSubscribed(user)) {
        return 'check'
      } else {
        return 'add'
      }
    },
    userIsSubscribed (user) {
      return this.report.settings.user_uids.includes(user.value)
    },
    toggleUser (user) {
      if (this.report.user_uid === user.value) return
      if (this.userIsSubscribed(user)) {
        // they are already subscribed
        let index = this.report.settings.user_uids.indexOf(user.value)
        this.report.settings.user_uids.splice(index, 1)
      } else {
        // add them
        this.report.settings.user_uids.push(user.value)
      }
    },
    async emailReport () {
      this.loading = true
      let fd = new FormData()
      fd.append('type', this.report.type)
      fd.append('settings', JSON.stringify(this.report.settings))
      fd.append('startDate', this.report.startDate)
      fd.append('endDate', this.report.endDate)

      try {
        await this.$axios.post(this.$consts.EMAIL_REPORT_URL, fd)
        this.$successMsg('Report Sent')
        this.loading = false
        this.$emit('closeForm')
      } catch (e) {
        this.$failureMsg()
        this.loading = false
      }
    }
  },
  async created () {
    this.loading = true
    this.report = JSON.parse(JSON.stringify(this.propReport))
    if (!this.report.isNew) {
      this.report.settings = JSON.parse(this.report.settings)
    }

    const res = await this.$store.dispatch('users/fetchFullList')
    this.usersList = res.map(user => {
      return {
        value: user.uid,
        label: user.name
      }
    })
    this.loading = false
  }
}
</script>
