<template>
  <q-card style="width: 825px; max-width: 825px" class="bg-lmsBackground primaryText-text">
    <q-card-section style="height: 5%;" class="row justify-between">
      <div class="text-h6">
        Select Shared Image
      </div>
      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>Close</q-tooltip>
      </q-btn>
    </q-card-section>
    <q-card-section style="height: 95%;">
      <q-scroll-area style="height: 100%;" ref="imageScrollArea">
        <div class="row full-width justify-around">
          <q-card v-for="image in images" :key="image.uid" class="cursor-pointer hoverCard col-5 q-my-md" @click="$emit('imageSelected', image)" >
            <q-img :src="image.path"></q-img>
          </q-card>
        </div>
        <div class="row full-width justify-center q-mt-lg">
          <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
        </div>
      </q-scroll-area>
    </q-card-section>
    <q-inner-loading :showing="loading">
      <q-spinner size="50px"/>
    </q-inner-loading>
  </q-card>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      allImages: [],
      loading: false,
      page: 1
    }
  },
  computed: {
    pageMax () {
      if (this.allImages.length === 0) return 0
      return Math.ceil(this.allImages.length / 20)
    },
    images () {
      let startIndex = (this.page - 1) * 20
      return this.allImages.slice(startIndex, startIndex + 20)
    },
    url () {
      if (this.type) {
        return `${this.$consts.GET_STORED_FILES_URL}?type=${this.type}`
      } else {
        return this.$consts.GET_STORED_FILES_URL
      }
    }
  },
  watch: {
    page () {
      this.$refs.imageScrollArea.setScrollPosition(0, 300)
    }
  },
  async created () {
    this.loading = true
    try {
      let res = await this.$axios.get(this.url)
      this.allImages = res.request
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }
}
</script>

<style lang="stylus">
.hoverCard
  position relative
  transition .3s
  &:hover
    box-shadow 0 7px 8px -2px rgba(0,0,0,0.2), 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12) !important
    border-color transparent
    transform translateY(-5px)
</style>
