<template>
  <q-drawer :value="opened" overlay bordered side="right" :width="574" :breakpoint="900" content-class="bg-lmsBackground primaryText-text" @hide="$emit('close')">
    <div class="q-pa-md full-height">
      <div class="row full-width justify-end">
        <q-btn dense flat icon="close" style="z-index: 2" @click="$emit('close')">
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </div>
      <div class="row items-center">
        <q-avatar class="q-mx-lg">
          <q-img :src="chatWith.avatar || $consts.DEFAULT_AVATAR" />
        </q-avatar>
        <div>
          <div class="text-h4">{{ chatWith.name }}</div>
          <div class="text-subtitle1 text-italic">{{ subject }}</div>
        </div>
      </div>
      <div class="row full-width q-mt-lg" style="height: 90%;">
        <q-separator color="primary" class="full-width" />
        <q-scroll-area style="height: 70%; width: 100%; max-width: 574px;" ref="chatScroll">
          <q-chat-message
            v-for="message in messages"
            :key="message.uid"
            :text="[message.body]"
            :sent="message.author_uid === currentUser"
            :avatar="getProp(message.author_uid, 'avatar') || $consts.DEFAULT_AVATAR"
            text-color="primaryText"
            :name="!replyable ? getProp(message.author_uid, 'name') : null"
            :stamp="!replyable ? getSendDate(message) : null"
          />
        </q-scroll-area>
        <q-card v-if="replyable" class="full-width bg-lmsBackground" flat>
          <q-form @submit="sendReply" autocomplete="off">
            <q-card-section>
              <q-editor v-model="reply" label="Message" min-height="6rem" max-height="9rem" content-class="bg-lmsBackground" toolbar-bg="lmsHeader" toolbar-text-color="white" toolbar-toggle-color="primaryText" :toolbar="[]" />
              <div class="row gutter-xs q-mt-md q-mx-lg">
                <div class="col-12">
                  <q-btn type="submit" class="float-right" :loading="sending" color="primary">Send</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-form>
        </q-card>
      </div>
    </div>
  </q-drawer>
</template>

<script>
import { date } from 'quasar'
export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    thread: {
      type: Object,
      default: () => {}
    },
    replyable: {
      type: Boolean,
      default: true
    },
    actingAs: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      reply: '',
      sending: false,
      chatWith: '',
      subject: ''
      // currentUser: {}
    }
  },
  computed: {
    messages () {
      return this.thread ? this.thread.messages : []
    },
    currentUser () {
      return this.actingAs !== '' ? this.actingAs : this.$store.state.auth.user.uid
    }
  },
  watch: {
    thread: function () {
      if (this.thread.messages.length > 0) {
        this.chatWith = this.thread.author.uid === this.currentUser ? this.thread.recipient : this.thread.author
        this.subject = this.thread.subject
        this.$refs.chatScroll.setScrollPosition(99999, 300)
      }
    }
  },
  methods: {
    getProp (authorUid, prop) {
      return authorUid === this.thread.author.uid ? this.thread.author[prop] : this.thread.recipient[prop]
    },
    getSendDate (message) {
      return date.formatDate(new Date(message.created_at + ' UTC'), 'MM/DD/YYYY h:mm a')
    },
    async sendReply () {
      let newReply = {
        parent_uid: this.thread.uid,
        recipient_uid: this.thread.author.uid === this.currentUser ? this.thread.recipient.uid : this.thread.author.uid,
        subject: this.thread.subject,
        body: this.reply
      }

      this.sending = true
      try {
        let res = await this.$axios.post(this.$consts.ADD_MESSAGE_URL, newReply)
        this.sending = false
        this.$successMsg()
        this.$emit('updateThread', res.data)
        this.reply = ''
      } catch (e) {
        this.sending = false
        this.$failureMsg()
      }
    }
  }
}
</script>
