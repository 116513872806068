<template>
  <q-item clickable :key="module.uid" class="q-py-lg" @click="linkable ? goTo() : $emit('moduleItemClick')" :active="active" active-class="bg-green-11">
    <q-item-section thumbnail>
      <q-img :src="module.thumbnail_path" style="width: 250px" class="q-ml-md" />
    </q-item-section>
    <q-item-section top>
      <q-item-label class="text-h5">{{ module.title }}</q-item-label>
      <q-item-label caption class="secondaryText-text">{{ module.description }}</q-item-label>
      <q-item-label lines="1" class="q-pa-xs q-pt-md">
        <q-chip clickable @click.stop="showTaggedMedia(tag, module.uid)" v-for="(tag, index) in module.tags" v-bind:key="index" dense square color="tertiary">{{tag}}</q-chip>
      </q-item-label>
    </q-item-section>
    <q-item-section top side>
      <slot name="topRightAction">
        <q-btn v-if="bookmarkable" dense round flat color="primary" :icon="this.$store.getters['bookmarks/isByUid'](module.uid) ? 'bookmark' : 'bookmark_border'" @click.stop="toggleBookmark(module)">
          <q-tooltip>Toggle Bookmark</q-tooltip>
        </q-btn>
      </slot>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  props: {
    module: {},
    bookmarkable: {
      default: true
    },
    linkable: {
      default: true
    },
    active: {
      default: false
    }
  },
  methods: {
    async showTaggedMedia (tag, excludeUid) {
      this.$store.dispatch('auth/setSearchModal', true)
      this.$moduleSearch.clearCache()
      await this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: '',
          params: {
            hitsPerPage: 50,
            filters: 'customer_uid:' + this.$store.getters['auth/company'].uid,
            facetFilters: [
              `tags:${tag}`,
              `uid:-${excludeUid}`,
              'browsable:true'
            ]
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.$store.dispatch('auth/setSearchResults', content.results['0'].hits)
      })
    },
    goTo () {
      this.$router.push({ name: 'module', params: { moduleId: this.module.uid } }).catch(() => {})
    },
    toggleBookmark (module) {
      let initialBookmarkState = module.bookmarked
      module.bookmarked = !module.bookmarked
      this.$q.loading.show({})
      this.$store.dispatch('bookmarks/bookmarkModule', { moduleId: module.uid }).then(res => {
        this.$q.loading.hide()
        this.$successMsg(initialBookmarkState ? 'Bookmark Removed' : 'Bookmark Added')
      })
    }
  }
}
</script>
