<template>
  <q-card style="width: 560px;" class="bg-lmsBackground primaryText-text" v-if="report && report.settings && report.settings.options">
    <q-card-section v-if="canEdit" class="row items-center justify-between">
      <div class="text-h5">Scheduled Reports</div>
      <q-toggle v-model="report.is_active" color="primary" label="Active" left-label/>
    </q-card-section>
      <q-stepper v-model="step" ref="stepper" color="primary" animated class="bg-lmsBackground primaryText-text">
        <q-step :name="1" title="Report Info" icon="info">
          <q-form @submit="step = report.type === 'importedModules' ? 3 : 2" @reset="$emit('closeForm')" autocomplete="off">
            <q-card-section v-if="canEdit">
              <div class="col-xs-12 q-mb-lg">
                <q-input v-model="report.label" label="Report Label" :rules="formRules.label" />
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input v-model="report.settings.description" label="Report Description" autogrow :rules="formRules.description" />
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-select :options="recurringOptions" map-options v-model="report.recurring_duration" label="Frequency" class="col-3" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.frequency" />
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input v-model="report.recurring_date" @click="() => $refs.recStartDate.show()" label="Starting On" class="col-3" :rules="formRules.startDate">
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy ref="recStartDate" transition-show="scale" transition-hide="scale">
                        <q-date v-model="report.recurring_date" @input="() => $refs.recStartDate.hide()" mask="MM/DD/YYYY" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </q-card-section>
            <q-stepper-navigation class="row justify-between">
              <q-btn flat type="reset" color="primary" label="Cancel" />
              <q-btn v-if="!report.isNew" flat @click="deleteReport" color="primary">Delete</q-btn>
              <q-btn type="submit" color="primary" label="Next" />
            </q-stepper-navigation>
          </q-form>
        </q-step>
        <q-step v-if="report.type !== 'importedModules'" :name="2" title="Report Details" icon="assignment">
          <q-form @submit="step = 3" @reset="step = 1" autocomplete="off">
            <q-card-section>
              <MediaSelect v-bind:selection.sync="report.settings.options.filterBy" label="Filter Data By" class=" col-3 q-mb-lg" mode="view" />
              <q-select class="col-3 q-my-lg" label="Group By" v-model="report.settings.options.groupBy" :value="report.settings.options.groupBy" :options="groupByOptions" map-options emit-value popup-content-class="bg-lmsBackground primaryText-text"  />
              <q-select class="col-3 q-my-lg" label="Data For" v-model="report.settings.duration" :options="dataLengthOptions" popup-content-class="bg-lmsBackground primaryText-text"  />
              <q-checkbox v-if="report.type === 'Media'" left-label v-model="report.settings.options.isComplete" label="Show Only Completed" />
              <q-select class="col-3 q-my-lg" label="Chart Type" emit-value map-options v-model="report.settings.chartType" :options="chartTypeOptions" popup-content-class="bg-lmsBackground primaryText-text"  />
            </q-card-section>
            <q-stepper-navigation class="row justify-between">
              <q-btn flat type="reset" color="primary" label="Back" class="q-ml-sm" />
              <q-btn v-if="!report.isNew" flat @click="deleteReport" color="primary">Delete</q-btn>
              <q-btn type="submit" color="primary" label="Next" />
            </q-stepper-navigation>
          </q-form>
        </q-step>
        <q-step :name="3" title="Summary" icon="description">
          <q-form @submit="report.isNew ? scheduleReport() : updateScheduledReport()" @reset="step = report.type === 'importedModules' ? 1 : 2" autocomplete="off">
            <q-card-section>
              <div class="text-h6 q-mb-md">Report Synopsis</div>
              <div class="q-my-lg">{{ reportTypeStatement }}</div>
              <div class="q-my-lg">{{ nextOccurenceStatement }}</div>
              <div v-if="report.type !== 'importedModules'" class="q-my-lg">{{ dataStatement }}</div>
              <div class="q-my-lg">{{ activeStatement }}</div>
            </q-card-section>
            <q-stepper-navigation v-if="canEdit || report.isNew" class="row justify-between">
              <q-btn flat type="reset" color="primary" label="Back" class="q-ml-sm" />
              <q-btn v-if="!report.isNew" flat @click="deleteReport" color="primary">Delete</q-btn>
              <q-btn type="submit" :loading="loading" color="primary">{{ report.isNew ? 'Schedule Report' : 'Update Report' }}</q-btn>
            </q-stepper-navigation>
          </q-form>
        </q-step>
      </q-stepper>

      <q-inner-loading :showing="loading">
        <q-spinner size="50px"/>
      </q-inner-loading>
  </q-card>
</template>

<script>
import { date } from 'quasar'
import MediaSelect from 'components/MediaSelect'

export default {
  components: { MediaSelect },
  props: {
    propReport: {
      default: () => {
        return {
          label: '',
          recurring_date: null,
          recurring_duration: null,
          settings: {
            chartType: 'spline',
            duration: '7',
            description: null,
            options: {
              filterBy: 'Company',
              groupBy: 'Locations',
              isComplete: false
            },
            user_uids: []
          },
          user_uids: null,
          isNew: true
        }
      }
    }
  },
  data () {
    return {
      step: 1,
      report: null,
      reportTypes: ['Login', 'Modules', 'Videos'],
      schedulingReport: false,
      filterByDefault: 'Company',
      filterByOptions: [
        { label: 'Company', id: 'Company' },
        { label: 'Location', id: 'Location', children: this.$store.getters['locations/treeList'] },
        { label: 'User Group', id: 'Department', children: this.$store.getters['departments/treelist'] }
      ],
      groupByOptions: [{ label: 'Locations', value: 'Locations' }, { label: 'User Groups', value: 'Departments' }],
      dataLengthOptions: [
        { label: 'Last 7 Days', value: 7 },
        { label: 'Last 30 Days', value: 30 },
        { label: 'Last 90 Days', value: 90 },
        { label: 'Last Year', value: 365 }
      ],
      chartTypeOptions: [
        { label: 'Line', value: 'spline' },
        { label: 'Bar', value: 'bar' },
        { label: 'Column', value: 'column' },
        { label: 'Area', value: 'area' }
      ],
      recurringOptions: ['Yearly', 'Quarterly', 'Monthly', 'Bi-Weekly', 'Weekly', 'Daily'],
      loading: false
    }
  },
  computed: {
    formRules () {
      return {
        label: [ this.$formRules.required('Label'), this.$formRules.maximum(40) ],
        description: [ this.$formRules.required('Description'), this.$formRules.maximum(200) ],
        frequency: [ this.$formRules.required('Frequency') ],
        startDate: [ this.$formRules.required('Start Date') ]
      }
    },
    canEdit () {
      return this.report.user_uid === this.$store.state.auth.user.uid || this.report.isNew
    },
    reportTypeStatement () {
      return `This is a ${this.report.type} report that will be sent out ${this.report.recurring_duration}.`
    },
    nextOccurenceStatement () {
      return `The next occurence will be sent out on ${date.formatDate(this.report.recurring_date, 'MMMM Do, YYYY')}.`
    },
    dataStatement () {
      return `It gathers data from the last ${this.report.settings.duration.value} days and will be grouped by ${this.report.settings.options.groupBy}.`
    },
    activeStatement () {
      return `It ${this.report.is_active ? 'is' : 'is not'} currently active.`
    }
  },
  methods: {
    formData () {
      let fd = new FormData()
      fd.append('type', this.report.type)
      fd.append('settings', JSON.stringify(this.report.settings))
      fd.append('label', this.report.label)
      fd.append('recurring_date', date.formatDate(this.report.recurring_date, 'MM/DD/YYYY'))
      fd.append('recurring_duration', this.report.recurring_duration)
      fd.append('is_active', this.report.is_active)

      return fd
    },
    async scheduleReport () {
      this.loading = true
      try {
        let res = await this.$axios.post(`${this.$consts.SCHEDULED_REPORTS_URL}/store`, this.formData())
        this.loading = false
        this.$emit('closeForm', { type: 'add', data: res })
        this.$successMsg()
      } catch (e) {
        this.loading = false
        this.$failureMsg()
      }
    },
    async updateScheduledReport () {
      this.loading = true
      try {
        let res = await this.$axios.post(`${this.$consts.SCHEDULED_REPORTS_URL}/update/${this.report.uid}`, this.formData())
        this.loading = false
        this.$emit('closeForm', { type: 'update', data: res })
        this.$successMsg()
      } catch (e) {
        this.loading = false
        this.$failureMsg()
      }
    },
    async deleteReport () {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete this scheduled report?`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(async () => {
        this.loading = true
        try {
          await this.$axios.delete(`${this.$consts.SCHEDULED_REPORTS_URL}/destroy/${this.report.uid}`)
          this.loading = false
          this.$emit('closeForm', { type: 'delete', data: this.report })
          this.$successMsg(`Successfully Deleted`)
        } catch (e) {
          this.loading = false
          this.$failureMsg()
        }
      })
    }
  },
  async created () {
    this.report = JSON.parse(JSON.stringify(this.propReport))
    if (!this.report.isNew) {
      this.report.settings = JSON.parse(this.report.settings)
    }
    if (!this.canEdit) {
      this.step = 3
    }
  }
}
</script>
