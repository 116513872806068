<template>
  <div>
    <q-btn flat @click="getModuleInfo">Preview</q-btn>

    <q-dialog v-model="showModal" maximized>
      <q-card class="bg-lmsBackground primaryText-text q-pa-lg">
        <div class="row full-width justify-between">
          <div class="text-h5">Module Preview</div>
          <q-btn dense flat icon="close" style="z-index: 2" @click="showModal = false">
            <q-tooltip>Close</q-tooltip>
          </q-btn>
        </div>
        <div class="row full-width q-mt-xl">
            <div class="col-12 row justify-between">
              <div>
                <q-skeleton v-if="loadingModule" type="text" width="500px" height="100px" />
                <div v-else class="text-h5">{{ module.title }}</div>
              </div>
              <q-btn v-if="mode && !loadingModule" color="primary" @click="$emit('selectModule', module)">{{ mode }} Module</q-btn>
            </div>
            <div class="col-12">
              <q-skeleton v-if="loadingModule" type="text" width="900px" height="50px" />
              <div v-else class="text-subtitle1">{{ module.description }}</div>
            </div>
        </div>
        <q-card-section v-if="loadingModule || moduleAssets.length > 0" class="row full-width justify-end">
          <div class="col-9">
            <div class="q-pa-sm">
              <q-skeleton v-if="loadingModule" type="rect" class="full-width" height="650px"/>
              <div v-else>
                <div id="videoBox" class="full-width" style="max-width: 1200px"></div>
                <div v-if="previewingAsset.test">
                  <q-list bordered>
                    <div v-for="(question, i) in previewingAsset.test" :key="i">
                      <q-expansion-item :label="`${i + 1}. ${question.content}`">
                        <q-card class="bg-lmsBackground primaryText-text">
                          <q-list>
                            <q-item v-for="(answer, j) in question.answers" :key="j">
                              <q-item-section avatar>
                                <q-icon v-if="answer.isChecked" color="positive" name="check_circle" />
                              </q-item-section>
                              <q-item-section>
                                <q-item-label>{{ answer.answer }}</q-item-label>
                                <q-item-label caption class="secondaryText-text">{{ answer.explanation }}</q-item-label>
                              </q-item-section>
                            </q-item>
                          </q-list>
                        </q-card>
                      </q-expansion-item>
                      <q-separator />
                    </div>
                  </q-list>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div v-if="loadingModule" class="row justify-end">
              <q-skeleton v-for="i in 20" :key="i" type="text" width="300px" height="32px" />
            </div>
            <div v-else class="row">
              <div class="col-12">Assets</div>
            </div>
            <q-list>
              <q-item v-for="(asset, i) in moduleAssets" :key="asset.uid" clickable @click="handleAssetClick(i)" :active="i === currentAsset">
                <q-item-section avatar>
                  <q-icon :name="getIcon(asset)" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ asset.name ? asset.name.split('.')[0] : 'Quiz'}}</q-item-label>
                  <q-item-label class="secondaryText-text" caption v-if="asset.extension === 'mp4' || asset.extension === 'qt' || asset.extension === 'mov'">Video</q-item-label>
                  <q-item-label class="secondaryText-text" caption v-else>Click to Open</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-card-section>
        <q-card-section v-else-if="moduleAssets.length < 1 && !loadingModule">
          <div class="row full-width justify-center q-my-xl">
            <div class="text-center text-h4">Sorry, there are currently no assets to preview for this module.</div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
export default {
  props: {
    moduleUid: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showModal: false,
      loadingModule: true,
      module: {},
      moduleAssets: [],
      azurePlayer: null,
      currentAsset: 0,
      loadingVideo: false,
      previewingAsset: {}
    }
  },
  computed: {
  },
  methods: {
    async getModuleInfo () {
      this.showModal = true

      await this.$axios.get(this.$consts.GET_MODULE_EDIT_URL + '/' + this.moduleUid).then((response) => {
        this.loadingModule = false
        this.module = response
        this.getModuleAssets()
      })
    },
    getModuleAssets () {
      let assets = this.module.media
      if (this.module.quizzes) {
        this.module.quizzes.forEach(quiz => {
          let matchingVideo = assets.find(video => video.uid === quiz.selectedMedia)
          let matchingVideoIndex = assets.indexOf(matchingVideo)
          if (matchingVideoIndex > -1) {
            assets.splice(matchingVideoIndex + 1, 0, quiz)
          } else if (quiz.selectedMedia === 'Module') {
            assets.push(quiz)
          }
        })
      }
      this.moduleAssets = assets

      setTimeout(() => {
        this.handleAssetClick(0)
      }, 100)
    },
    buildVideoElement () {
      let parent = document.getElementById('videoBox')
      parent.innerHTML = `<video id="video1" crossorigin="anonymous" controls class="azuremediaplayer amp-default-skin amp-big-play-centered" style="min-height: 600px" tabindex="0" data-setup='{ "controls": true, "autoplay": false, "fluid": true }'></video>`
    },
    loadAmpVideo (index) {
      this.buildVideoElement()
      this.loadingVideo = true
      let videoAsset = this.moduleAssets[index || this.currentAsset]
      this.azurePlayer = amp('video1', {
        autoplay: false,
        controls: true,
        logo: { enabled: false },
        src: ''
      })
      this.azurePlayer.src([{ src: (videoAsset.url !== undefined) ? videoAsset.url : videoAsset.location_url, type: videoAsset.type }])
      this.loadingVideo = false
    },
    handleAssetClick (index) {
      if (this.moduleAssets.length < 1) return
      if (this.azurePlayer) {
        this.azurePlayer.dispose()
        this.azurePlayer = null
      }
      this.currentAsset = index
      this.showBanner = this.moduleAssets[this.currentAsset].extension !== 'mp4' && this.moduleAssets[this.currentAsset].extension !== 'qt' && this.moduleAssets[this.currentAsset].extension !== 'mov'
      let asset = this.moduleAssets[index]
      this.previewingAsset = this.moduleAssets[index]
      switch (asset.extension) {
        case 'mp4':
        case 'qt':
        case 'mov':
          // needing to wait or else error will be thrown
          setTimeout(() => {
            this.loadAmpVideo(index)
          }, 100)
          break
        case 'pdf':
        case 'jpg':
        case 'jpeg':
        case 'png':
          // this.showBanner = true
          window.open(asset.url)
          break
        default:
          // this.openQuiz(index, this.moduleAssets[index])
          break
      }
    },
    getIcon (asset) {
      let icon
      switch (asset.extension) {
        case 'mp4':
        case 'qt':
        case 'mov':
          icon = 'ondemand_video'
          break
        case 'pdf':
          icon = 'picture_as_pdf'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
          icon = 'image'
          break
        default:
          icon = 'school'
          break
      }
      return icon
    }
  },
  created () {
  }
}
</script>

<style lang="stylus" scoped>
.loadingVideo
  width 100% !important
  height 500px !important
  background-color black
</style>
