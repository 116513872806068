<template>
  <div class="row q-mt-xl">
    <div v-if="title" class="col-12 q-mb-md">
      <div class="row col-12">
        <div class="text-h6">{{ title }}</div>
      </div>
      <q-separator />
    </div>
    <div class="row q-gutter-lg" v-if="modules.length > 0">
      <module-grid-item v-for="module in modules" :module="module" :key="module.uid" />
    </div>
    <div class="row full-width justify-center" v-else>
        <div class="text-h5 text-italic">{{ emptyMessage }}</div>
    </div>
  </div>
</template>

<script>
import ModuleGridItem from 'components/ModuleGridItem'

export default {
  name: 'ModulesGrid',
  components: { ModuleGridItem },
  props: {
    modules: {},
    bookmarkable: {
      default: true
    },
    emptyMessage: {
      default: 'No Modules'
    },
    title: {
      default: ''
    }
  },
  data () {
    return {
      toggleBookmark (module) {
        let initialBookmarkState = module.bookmarked
        module.bookmarked = !module.bookmarked
        this.$q.loading.show({})
        this.$store.dispatch('bookmarks/bookmarkModule', { moduleId: module.uid }).then(res => {
          this.$q.loading.hide()
          this.$successMsg(initialBookmarkState ? 'Bookmark Removed' : 'Bookmark Added')
        })
      }
    }
  }
}
</script>
