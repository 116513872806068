<template>
  <q-select v-model="selectionName" :label="label" popup-content-class="treeSelectPopUp bg-lmsBackground primaryText-text" use-input @filter="filterFn" input-debounce="0" options-cover>
    <template v-slot:no-option>
      <TreeMenuItem v-for="option in options" :key="option.id" :option="option" :inset="0" :enableBranchNodes="enableBranchNodes" @selection="itemSelected"  :filterString="filter" :excludedUid="excludedUid" />
    </template>
  </q-select>
</template>

<script>
import TreeMenuItem from 'components/TreeMenuItem'

export default {
  name: 'MediaSelect',
  props: {
    label: {
      type: String
    },
    selection: {
      type: String
    },
    isMedia: {
      type: Boolean,
      default: false
    },
    enableBranchNodes: {
      type: Boolean,
      default: false
    },
    excludedUid: {
      type: String
    },
    includeCompany: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  components: { TreeMenuItem },
  data () {
    return {
      filter: null,
      selectionName: 'Select',
      flatOptions: []
    }
  },
  computed: {
    assignOptions () {
      if (this.$store.getters['auth/userDepartments'].length > 0) {
        return [
          { label: 'Company', id: 'Company' },
          { label: 'My Location', id: this.$store.getters['auth/customer'] },
          { label: 'My User Groups', id: 'Department', children: this.$store.getters['auth/userDepartments'] }
        ]
      } else {
        return [
          { label: 'Company', id: 'Company' },
          { label: 'My Location', id: this.$store.getters['auth/customer'] }
        ]
      }
    },
    options () {
      let options = []

      if (this.mode === 'view') {
        // just viewing, only show them the company, their location, and their departments
        // options = this.assignOptions
        if (this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
          // site admin - show the company, all locations, and all departments
          options.push(this.assignOptions[0])
          options.push({ label: 'Locations', id: 'Location', children: this.$store.getters['locations/treeList'] })
          options.push({ label: 'User Groups', id: 'Department', children: this.$store.getters['departments/treelist'] })
        } else {
          // not site admin (probably location admin) - show them only their location and all departments
          options.push(this.assignOptions[0])
          options.push(this.assignOptions[1])
          options.push(this.assignOptions[2])
        }
      } else {
        // editing
        if (this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
          // site admin - show the company, all locations, and all departments
          options.push(this.assignOptions[0])
          options.push({ label: 'Location', id: 'Location', children: this.$store.getters['locations/treeList'] })
          options.push({ label: 'User Group', id: 'Department', children: this.$store.getters['departments/treelist'] })
        } else {
          // not site admin (probably location admin) - show them only their location and all departments
          options.push(this.assignOptions[1])
          options.push({ label: 'User Group', id: 'Department', children: this.$store.getters['departments/treelist'] })
        }
      }

      return options
    }
  },
  methods: {
    itemSelected (item) {
      this.selectionName = item.label
      this.$emit('update:selection', item.id)
      this.$emit('input', item)
      this.filter = null
    },
    flattenOptions (level = this.options) {
      level.forEach(option => {
        this.flatOptions.push(option)
        if (option.children) {
          this.flattenOptions(option.children)
        }
      })
    },
    filterFn (val, update) {
      this.filter = val
      if (val.length > 0) {
        this.selectionName = ''
      }
      update(() => { })
    }
  },
  created () {
    this.flattenOptions()
    this.selectionName = this.flatOptions.find(option => option.id === this.selection).label
  }
}
</script>

<style lang="stylus">
.treeSelectPopUp
  max-height 400px
</style>
