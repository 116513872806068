<template>
  <q-select
    multiple
    map-options
    :emit-value="emitValue"
    :label="label"
    :value="currentSelections"
    use-input
    use-chips
    input-debounce="0"
    :options="filteredOptions"
    @filter="filterFn"
    @input="$emit('update:selection', $event)"
    popup-content-class="bg-lmsBackground primaryText-text"
    :rules="rules"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section avatar>
          <q-checkbox v-model="currentSelections" :val="scope.opt.value" keep-color color="primary" />
        </q-item-section>
        <q-item-section>
          <q-item-label v-html="scope.opt.label"></q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  props: {
    label: String,
    options: Array,
    selection: Array,
    emitValue: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filter: ''
    }
  },
  computed: {
    filteredOptions () {
      return this.options.filter(v => v.label.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
    },
    currentSelections: {
      // getter
      get: function () {
        return this.selection
      },
      // setter
      set: function (newValue) {
        this.$emit('update:selection', newValue)
      }
    }
  },
  methods: {
    filterFn (val, update, abort) {
      this.filter = val
      update(() => {
      })
    }
  }
}
</script>
