<template>
  <q-card flat bordered class="cursor-pointer moduleCard bg-lmsBackground" style="min-width: 250px;" :key="module.uid" @click="$router.push({ name: 'module', params: { moduleId: module.uid } }).catch(() => {})">
    <q-img :src="module.thumbnail_path">
      <q-btn dense round flat color="primary" class="float-right" :icon="this.$store.getters['bookmarks/isByUid'](module.uid) ? 'bookmark' : 'bookmark_border'" @click.stop="toggleBookmark(module)">
        <q-tooltip>Toggle Bookmark</q-tooltip>
      </q-btn>
    </q-img>
    <div class="modulePlay row justify-center">
      <q-icon name="play_circle_outline" size="48px"/>
    </div>
    <q-card-section class="row" style="justify-content: space-between; align-items: center;">
      <div>
        <span class="text-subtitle2">{{ module.title | truncate(25) }}</span>
        <br />
        <span class="text-caption">{{ module.description | truncate(30) }}</span>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: {
    module: {}
  },
  methods: {
    toggleBookmark (module) {
      let initialBookmarkState = module.bookmarked
      module.bookmarked = !module.bookmarked
      this.$q.loading.show({})
      this.$store.dispatch('bookmarks/bookmarkModule', { moduleId: module.uid }).then(res => {
        this.$q.loading.hide()
        this.$successMsg(initialBookmarkState ? 'Bookmark Removed' : 'Bookmark Added')
      })
    }
  },
  filters: {
    truncate (string, amount) {
      return string.substring(0, amount)
    }
  }
}
</script>

<style lang="stylus" scoped>
.modulePlay
  position absolute
  top 25%
  width 100%
  transition .3s
  transition-delay .15s
  opacity 0
.moduleCard
  position relative
  transition .3s
  &:hover
    box-shadow 0 7px 8px -2px rgba(0,0,0,0.2), 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12) !important
    border-color transparent
    transform translateY(-5px)

    .modulePlay
      opacity 1
</style>
