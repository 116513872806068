<template>
  <q-card style="width: 560px;" class="bg-lmsBackground primaryText-text">
      <q-card-section class="row items-center justify-between">
        <div class="text-h5">Search Users</div>
        <q-btn label="Download List" color="primary" @click="downloadUserList" />
        <q-btn dense flat icon="close" style="z-index: 2" @click="$emit('closeForm')">
          <q-tooltip>Close</q-tooltip>
        </q-btn>
      </q-card-section>
      <q-card-section class="row items-middle justify-around">
        <div class="text-body1">Find User By:</div>
        <q-radio v-model="findByType" val="Location" label="Location" color="primary" keep-color />
        <q-radio v-model="findByType" val="User Group" label="User Group" color="primary" keep-color />
      </q-card-section>
      <q-card-section>
        <q-select filled map-options dense :label="`Select ${findByType}`" v-model="findByVal" :options="findByList" class="col-5" popup-content-class="bg-lmsBackground primaryText-text"></q-select>
      </q-card-section>
      <q-card-section>
        <q-input v-model="filter" label="Filter Users" clearable>
          <template v-slot:prepend>
            <q-icon name="search" class="primaryText-text" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section>
        <q-list separator>
          <q-scroll-area style="height: 500px" ref="userScrollArea">
            <q-item v-for="(user) in users" :key="user.value" clickable @click="multiple ? user.selected = !user.selected : selectUser(user)">
              <q-item-section>{{ user.label }}</q-item-section>
              <q-item-section avatar v-if="!isSelf(user)">
                <q-checkbox v-if="multiple" v-model="user.selected" />
                <div v-else class="text-uppercase text-weight-medium q-mx-sm">Select</div>
              </q-item-section>
            </q-item>
            <div class="row full-width justify-center q-mt-lg">
              <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
            </div>
          </q-scroll-area>
        </q-list>
      </q-card-section>
      <div v-if="multiple" class="row full-width">
        <q-btn color="primary" class="full-width" :disable="selectedUsers.length < 1" @click="selectUser(selectedUsers)">Finish Selections</q-btn>
      </div>
      <q-inner-loading :showing="loading">
        <q-spinner size="50px"/>
      </q-inner-loading>
  </q-card>
</template>

<script>
import XLSX from 'xlsx'
export default {
  props: {
    scoped: {
      type: Boolean,
      default: false
    },
    allowSelfSelect: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      usersList: [],
      usersListOptions: [],
      loading: false,
      filter: '',
      findByType: 'Location',
      findByVal: null,
      location: null,
      department: null,
      page: 1
    }
  },
  computed: {
    pageMax () {
      if (this.filteredUsers.length === 0) return 0
      return Math.ceil(this.filteredUsers.length / 25)
    },
    users () {
      let startIndex = (this.page - 1) * 25
      return this.filteredUsers.slice(startIndex, startIndex + 25)
    },
    filteredUsers () {
      if (this.filter === '' || this.filter === null) return this.usersListOptions
      return this.usersListOptions.filter(user => user.label.toLowerCase().includes(this.filter.toLowerCase()))
    },
    findByList () {
      if (this.$isAllowed(['||', 'master-admin', 'site-admin'], true) || !this.scoped) {
        return this.findByType === 'Location' ? [{ label: 'All Locations', value: 'all' }, ...this.$store.getters['locations/list']] : [{ label: 'All User Groups', value: 'all' }, ...this.$store.getters['departments/list']]
      }

      if (this.scoped && this.findByType === 'Location') {
        return this.$store.getters['locations/list'].filter(location => location.value === this.$store.getters['auth/customer'])
      } else if (this.scoped && this.findByType === 'User Group') {
        return this.$store.getters['auth/userDepartments']
      } else {
        return this.findByType === 'Location' ? this.$store.getters['locations/list'] : this.$store.getters['departments/list']
      }
    },
    selectedUsers () {
      // only used if multiple is true
      return this.users.filter(user => user.selected)
    }
  },
  watch: {
    async findByVal (newVal) {
      if (!newVal) return
      // Needing to find way to get list of users in department
      this.loading = true
      let action = newVal.value === 'all' ? 'users/fetchFullList' : (this.findByType === 'Location' ? 'users/fetchList' : 'users/fetchListByDepartment')
      let res = await this.$store.dispatch(action, this.findByVal.value || this.findByVal.id)

      if (this.multiple) {
        res.forEach(user => {
          user.selected = false
        })
      }
      this.page = 1
      this.usersList = res
      this.usersListOptions = res.map(user => {
        return this.multiple ? { value: user.uid, label: user.name, selected: user.selected } : { value: user.uid, label: user.name }
      })
      this.loading = false
    },
    findByList () {
      this.findByVal = null
    },
    page () {
      this.$refs.userScrollArea.setScrollPosition(0, 300)
    },
    filter (newVal) {
      if (newVal) {
        this.page = 1
      }
    }
  },
  methods: {
    isSelf (user) {
      if (!this.allowSelfSelect) {
        return this.$store.state.auth.user.uid === user.value
      }
    },
    selectUser (user) {
      this.$emit('userSelected', user)
    },
    downloadUserList () {
      const wb = XLSX.utils.book_new()
      const sheetData = this.usersList.map(user => {
        return { name: user.name, email: user.email }
      })
      const ws = XLSX.utils.json_to_sheet(sheetData, { skipHeader: true, origin: 'A2' })
      XLSX.utils.sheet_add_json(ws, [{ name: 'Name', email: 'Email Address' }], { skipHeader: true, origin: 'A1' })
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
      XLSX.writeFile(wb, `${this.$slugify(this.findByVal.label)}_users.xlsx`)
    }
  },
  async created () {
    this.loading = true

    this.findByVal = { value: this.$store.getters['auth/user'].customer.uid, label: this.$store.getters['auth/user'].customer.name }
    this.loading = false
  }
}
</script>
