<template>
  <q-select
    emit-value
    map-options
    :label="label"
    :value="selection"
    use-input
    options-cover
    input-debounce="0"
    :options="filteredOptions"
    @filter="filterFn"
    @input="$emit('update:selection', $event)"
    popup-content-class="bg-lmsBackground primaryText-text"
    :rules="rules"
    autocomplete="no"
  >

    <template v-if="thumbnail" v-slot:option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section thumbnail class="q-pt-sm q-pb-sm q-pl-md q-pr-md">
          <img :src="scope.opt.thumbnail_path" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ scope.opt.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  props: {
    label: String,
    options: Array,
    selection: String,
    thumbnail: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filter: ''
    }
  },
  computed: {
    filteredOptions () {
      return this.options.filter(v => v.label.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
    }
  },
  methods: {
    filterFn (val, update, abort) {
      this.filter = val
      update(() => { })
    }
  }
}
</script>
